import axios from 'axios';
import { getCookie, removeCookie } from 'typescript-cookie';
import { UserService } from './UserService';

export const BASE_API_URL = process.env.NODE_ENV === 'development'
  ? 'http://localhost:8000/api'
  : '/api';
export const apiClient = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use((config) => {
  const accessToken = getCookie('user_grant_access');
  if (accessToken) {
    if (config.url !== '/users/check-token') {
      UserService.checkToken()
        .then(() => {
          if (config.headers) {
            // config.headers.Authorization = accessToken;
            config.headers.Authorization = `Bearer ${accessToken}`;
          }
        })
        .catch(() => {
          removeCookie('user_grant_access');
          removeCookie('user_data');
          removeCookie('checkToken');
          window.location.href = '/login';
        })
        .finally(() => {
          return;
        });
    }

    if (config.headers) {
      // config.headers.Authorization = accessToken;
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
  }

  return config;
});

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const expiredToken = error.response.status === 500
      && error.response.data
      && error.response.data.detail === 'Expired token';
    if (expiredToken || error.response.status === 401) {
      removeCookie('user_grant_access');
      removeCookie('user_data');
      removeCookie('checkToken');
      window.location.href = '/';
    }
    return Promise.reject(error);
  },
);