import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { getCookie, removeCookie } from 'typescript-cookie';

import { useUserStore } from '@/stores/useUserStore';
import { useCalendarStore } from '@/stores/useCalendarStore';
import { useThemeStore } from '@/stores/useThemeStore';
import dayjs from 'dayjs';
import { UserService } from '@/services/UserService';

import {
  DEFAULT_START_DATE,
} from '@/utils/constants/calendars';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */'@/views/home/index.vue'),
    meta: {
      layout: 'MainLayout',
      hasSearch: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */'@/views/login/index.vue'),
    meta: {
      layout: 'LoginLayout',
    },
  },
  {
    path: '/customers',
    name: 'customers',
    component: () => import(/* webpackChunkName: "login" */'@/views/customers/index.vue'),
    meta: {
      layout: 'MainLayout',
    },
  },
  {
    path: '/products/:masterArticleNumber',
    name: 'products_masterArticleNumber',
    component: () => import(/* webpackChunkName: "home" */'@/views/products/_masterArticleNumber/index.vue'),
    meta: {
      layout: 'MainLayout',
      hasBackButton: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const accessToken = getCookie('user_grant_access');
  const nextRoute = to.name?.toString() || '';
  const isLoginPage = ['login'].includes(nextRoute);
  if (!accessToken && isLoginPage) {
    next();
  } else if (!accessToken) {
    next({ name: 'login' });
  } else {
    const themeStore = useThemeStore();
    themeStore.setBreadcrumb([]);
    try {
      await UserService.checkToken();
      const userStore = useUserStore();
      userStore.setUserDataFromCookie();

      //set up calendar date
      const calendarStore = useCalendarStore();
      const calendarDateRangeStorage = sessionStorage.getItem('calendarDateRange');
      // Check if has save select date in session and has select date in pinia
      if (calendarDateRangeStorage) {
        const savedCalendarDateRange = JSON.parse(calendarDateRangeStorage);
        let startDate = new Date(savedCalendarDateRange.start);
        const endDate = new Date(savedCalendarDateRange.end);
        // If refresh page then set to pinia
        if (
          !calendarStore.calendarDateRange.start
          && !calendarStore.calendarDateRange.end
        ) {
          const startTime = startDate.getTime();
          const endTime = endDate.getTime();
          if (startTime > endTime) {
            startDate = new Date(DEFAULT_START_DATE);
          }
          calendarStore.setDate({ start: startDate, end: endDate });
        }
        // If session storage and state not the same then set to pinia
        else if (
          calendarStore.calendarDateRange.start
          && calendarStore.calendarDateRange.end
          && !dayjs(calendarStore.calendarDateRange.start).isSame(dayjs(savedCalendarDateRange.start))
          && !dayjs(calendarStore.calendarDateRange.end).isSame(dayjs(savedCalendarDateRange.end))
        ) {
          calendarStore.setDate({ start: startDate, end: endDate });
        }
      } else {
        // if pinia select date store is empty
        calendarStore.setInitDate();
      }

      if (isLoginPage) {
        next({ name: 'home' });
      } else {
        next();
      }
    } catch {
      removeCookie('user_grant_access');
      removeCookie('user_data');
      removeCookie('checkToken');
      window.location.href = '/login';
    }
  }
});

export default router;