import { defineStore } from 'pinia';
import {
  BreadcrumbItem,
} from '@/utils/types/breadcrumb';

export const useThemeStore = defineStore('theme', {
  state: () => ({
    isSidebarMinimized: true,
    breadcrumb: [] as BreadcrumbItem[],
  }),
  actions: {
    toggleSidebar() {
      this.isSidebarMinimized = !this.isSidebarMinimized;
    },
    showSidebar () {
      this.isSidebarMinimized = false;
    },
    hideSidebar () {
      this.isSidebarMinimized = true;
    },
    setBreadcrumb(items: BreadcrumbItem[]) {
      this.breadcrumb = items;
    },
  },
  getters: {
    hasBreadcrumb (): boolean {
      return this.breadcrumb.length > 0;
    },
  },
});