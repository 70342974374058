interface NumberFormatterConfig {
  format?: string,
  decimal?: number,
}

export const useFormatter = () => {
  function quantityFormatter (inputNumber: number, format: string = 'de-DE') {
    return new Intl.NumberFormat(format).format(inputNumber);
  }

  function numberFormatter (inputNumber: number, config: NumberFormatterConfig = {}) {
    const {
      format = 'de-DE',
      decimal = 0,
    } = config;
    return new Intl.NumberFormat(format, {
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal,
    }).format(inputNumber);
  }

  return {
    quantityFormatter,
    numberFormatter,
  };
};