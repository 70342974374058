import { apiClient } from '@/services';
import {
  LoginData,
  RegisterData,
  PasswordForgotData,
} from '@/utils/types/user';
import { setCookie, getCookie } from 'typescript-cookie';

export const UserService = {
  login(payload: LoginData) {
    return apiClient.post('/users/login', payload);
  },

  logout() {
    return apiClient.get('/users/logout');
  },

  checkToken() {
    if (getCookie('checkToken')) {
      return Promise.resolve();
    }

    const inTwoHours = new Date(new Date().getTime() + 2 * 60 * 60 * 1000);
    setCookie('checkToken', 'token', { expires: inTwoHours });

    return apiClient.get('/users/check-token');
  },
};