import { defineStore } from 'pinia';
import {
  CalendarRange,
} from '@/utils/types/calendar';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import {
  DEFAULT_START_DATE,
  DEFAULT_END_DATE,
} from '@/utils/constants/calendars';
dayjs.extend(localizedFormat);

export const useCalendarStore = defineStore('filterCalendar', {
  state: () => {
    return {
      calendarDateRange: {
        start: null,
        end: null,
      } as CalendarRange,
      currentLocale: 'de',
    };
  },
  actions: {
    setInitDate () {
      const startDate = new Date(DEFAULT_START_DATE);
      this.calendarDateRange.start = startDate;
      const endDate = new Date(DEFAULT_END_DATE);
      this.calendarDateRange.end = endDate;
      sessionStorage.setItem('calendarDateRange', JSON.stringify({
        start: startDate,
        end: endDate,
      }));
    },
    setDate (data: CalendarRange) {
      this.calendarDateRange = {
        start: data.start,
        end: data.end,
      };
      sessionStorage.setItem('calendarDateRange', JSON.stringify(data));
    },
  },
  getters: {
    getFormattedDate(stateData) {
      const startDate = stateData.calendarDateRange.start
        ? dayjs(stateData.calendarDateRange.start).locale(stateData.currentLocale).format('ll')
        : '';
      const endDate = stateData.calendarDateRange.end
        ? dayjs(stateData.calendarDateRange.end).locale(stateData.currentLocale).format('ll')
        : '';

      return `${startDate} - ${endDate}`;
    },
  },
});